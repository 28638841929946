$homepage-tagline-padding-mobile: (
  $site-margin * 3) 0 !default;
$homepage-tagline-padding-tablet: 45px 0 0 !default;
$homepage-tagline-padding-desktop: 70px 0 0 !default;
$text-shadow-color: rgb(0 0 0 / 90%
);

$homepage-image-min-height-mobile: 220px !default;
$homepage-image-min-height-tablet: 230px !default;
$homepage-image-min-height-medium: 240px !default;
$homepage-image-min-height-desktop: 250px !default;

.homepage-image-container-background {
  position: relative;
  background-image: $background-image;
  background-image: $background-overlay-gradient, $background-image;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: $homepage-image-min-height-mobile;

  @include media($media-tablet) {
    min-height: $homepage-image-min-height-tablet;
  }

  @include media($media-medium) {
    padding: 0 $site-margin * 2;
    min-height: $homepage-image-min-height-medium;
  }

  @include media($media-desktop) {
    min-height: $homepage-image-min-height-desktop;
  }
}

.homepage-tagline {
  @include outer-container;

  padding: $homepage-tagline-padding-mobile;
  text-align: center;
  text-shadow: 0 1px 0 $text-shadow-color;
  color: $color-white;
  font-family: $font-brand-normal;
  font-weight: 300;

  .slogan-headline {
    margin: 0 $site-margin $site-margin;
    font-family: $font-brand-bold;
    font-weight: 700;

    @include media($media-not-mobile) {
      font-size: 1.9em;
    }

    @include media($media-not-portable) {
      font-size: 2.5em;
    }

    @include media($media-desktop) {
      margin-bottom: $site-margin;
      font-size: 3em;
    }
  }

  .slogan-subtitle {
    margin: 0 $site-margin;
    font-family: $font-brand-normal;
    font-size: 1em;
    font-weight: 300;

    @include media($media-not-mobile) {
      margin-bottom: $site-margin * 3;
      font-size: 1.2em;
    }

    @include media($media-not-portable) {
      margin-bottom: $site-margin * 4;
      font-size: 1.6em;
    }
  }

  .slogan-image {
    display: none;

    &-logo {
      max-width: 200px;

      @include media($media-not-portable) {
        max-width: 250px;
      }
    }

    @include media($media-not-mobile) {
      display: block;
    }
  }

  @include media($media-tablet) {
    padding: $homepage-tagline-padding-tablet;
  }

  @include media($media-not-portable) {
    padding: $homepage-tagline-padding-desktop;
  }
}

.homepage-marketing-container {
  @include content-container;

  position: relative;
  top: -55px;

  +.content {
    border-radius: 10px 10px 0 0;
  }
}

.homepage-marketing-panel {
  @include span-columns(12);

  text-align: center;
  text-shadow: none;
  color: $color-black;

  .partners {
    color: $color-black;
  }
}
