@mixin popout-autocomplete-results() {
  .inline-autocomplete-results {
    position: absolute;
    bottom: 105%;
    z-index: 100;
    outline: 5px solid rgb(224 224 224 / 50%);
    border: 1px solid $color-grey-300;
    background: $color-white;
    max-height: inherit;
    overflow: inherit;

    &::after,
    &::before {
      position: absolute;
      top: 100%;
      left: 50%;
      z-index: 99;
      border: solid transparent;
      width: 0;
      height: 0;
      content: '';
      pointer-events: none;
    }

    &::after {
      margin-left: -10px;
      border-width: 10px;
      border-color: transparent;
      border-top-color: $color-white;
    }

    &::before {
      margin-left: -11px;
      border-width: 11px;
      border-color: transparent;
      border-top-color: $color-grey-300;
    }
  }

  .autocomplete-item {
    padding: 0;
    line-height: 32px;

    &:last-child {
      border-bottom: 0;
    }
  }
}

$background-color: #fff;

.homepage-search-form-container {
  @include outer-container;

  .search-form {
    @include horizontal($media-not-portable);

    position: relative;
    top: -60px;
    margin: 0 $site-margin;
    border-radius: $site-border-radius;
    box-shadow: 0 2px 7px 0 #aaa;
    background: $background-color;
    padding: $site-margin * 2;

    .date-label,
    .vehicle-group-header,
    .location-header,
    .promo-code-label,
    .source-market-label,
    .reservation-form-label,
    .timeframe-header,
    .optional-input-label {
      font-size: 1em;

      @include media($media-not-portable) {
        font-size: 1.2em;
      }
    }

    .vehicle-group-wrapper {
      align-items: center;
      flex-flow: row wrap;
      column-gap: $site-margin;
      margin: -$site-margin * 2;
      margin-bottom: $site-margin * 2;
      border-bottom: $input-border;
      border-top-left-radius: $site-border-radius;
      border-top-right-radius: $site-border-radius;
      background-color: lighten($color-partner-secondary, 35%);
      padding: calc($site-margin * 3 / 2) calc($site-margin * 2) $site-margin;
    }

    .submit-button {
      transition: box-shadow 500ms;
    }

    .submit-button:hover {
      box-shadow: 0 0 5px #133;
    }

    @include media($media-not-portable) {
      top: -80px;
    }
  }
}

.search-form-desktop-panel .search-form-panel {
  display: block;
}
