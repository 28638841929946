$homepage-image-min-height-mobile: 240px !default;
$homepage-image-min-height-tablet: 300px !default;
$homepage-image-min-height-medium: 400px !default;
$homepage-image-min-height-desktop: 410px !default;

@import 'partner-colors';
@import 'skins/normal/main';

@import 'components/component.destinations';
@import 'components/component.faq';
@import 'components/component.imprint';
@import 'components/component.contact';
@import 'components/component.privacy';
