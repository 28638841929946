@import 'partner-colors';
@import 'https://static.carhire-solutions.com/fonts/gotham-rounded/gotham-rounded-book-300.css';
@import 'https://static.carhire-solutions.com/fonts/gotham-rounded/gotham-rounded-bold-700.css';

$color-footer-border-top: #eee !default;
$color-usp-icon: $color-partner-secondary !default;
$color-background-main: #fafafa !default;
$background-image: url('https://static.carhire-solutions.com/images/wlp/other/homepage-bg-normal.jpg') !default;
$background-overlay-gradient-top: rgb(0 0 0 / 40%) !default;
$background-overlay-gradient-bottom: rgb(50 50 50 / 10%) !default;

$max-width: 1200px !default;

@import 'libraries.imports';
@import 'imports/required';
@import 'imports/base-styles';
@import 'application.imports';

$font-brand-normal: 'Gotham Rounded', arial, sans-serif;
$font-brand-bold: 'Gotham Rounded', arial, sans-serif;
$color-link-footer: $color-text-dark !default;

@import 'component.imports';
@import 'template.imports';
@import 'sidebar';
@import 'header';
@import 'homepage';
@import 'searchForm';
@import 'footer';
@import 'site';
