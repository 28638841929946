$background-color: $color-partner-yellow;

.jumbotron.destinations {
  background: $color-white url('https://static.carhire-solutions.com/images/wlp/jumbotron-sunset.jpg') no-repeat;
  background-size: cover;
  background-position-x: center;
}

.destinations-list {
  float: left;
  clear: both;
  margin-top: 0;
  padding: 0;
  width: 100%;

  li {
    @include span-columns(12);

    a {
      float: left;
      padding: calc($site-margin / 2) $site-margin * 0.75;
      width: 100%;
      text-decoration: none;
      color: $color-text-dark;
    }

    a:hover {
      background: #eee;
    }

    .cnx-flag {
      display: inline-block;
      margin-right: calc($site-margin / 2);
      vertical-align: -1px;
    }

    @include media($media-tablet) {
      @include omega(2n);
      @include span-columns(6);
    }

    @include media($media-not-portable) {
      @include omega(4n);
      @include span-columns(3);
    }
  }
}

.destinations-search-form-container {
  @include outer-container;

  box-shadow: 0 10px 15px -5px #d9d9d9;
  background: $background-color;

  .search-form {
    @include horizontal($media-not-portable);

    position: relative;
    margin: 0;
    border-radius: $site-border-radius;
    background: $background-color;
    padding: $site-margin * 2;

    .date-label,
    .location-header,
    .promo-code-label,
    .source-market-label,
    .reservation-form-label,
    .timeframe-header,
    .optional-input-label {
      color: $color-partner-primary;
      font-size: 1em;

      @include media($media-not-portable) {
        font-size: 1em;
      }
    }

    .submit-button {
      transition: box-shadow 500ms;
    }

    .submit-button:hover {
      box-shadow: 0 0 5px #333;
    }
  }
}

.traffic-rules {
  th {
    vertical-align: top;
    white-space: nowrap;
  }

  th + td {
    padding-left: $site-margin;
  }
}
