// stylelint-disable selector-no-qualifying-type

.jumbotron.faq {
  background: $color-white url('https://static.carhire-solutions.com/images/wlp/jumbotron-faq.jpg') no-repeat;
  background-size: cover;
  background-position-x: center;

  form {
    clear: both;
    margin: 0 auto;
    padding: 0 $site-margin $site-margin;
    width: 100%;

    label {
      display: block;
      margin-top: 0;
      margin-bottom: 10px;
      padding-top: $site-margin;
      text-align: center;
      text-shadow: 1px 1px 0 #000;
      color: #fff;
      font-size: 1.6em;

      @include media($media-not-mobile) {
        padding-top: 0;
      }

      @include media($media-not-portable) {
        margin-bottom: 12px;
        font-size: 2em;
      }
    }

    input {
      width: 100%;
      text-align: center;
      font-size: 1.2em;
    }

    @include media($media-not-mobile) {
      max-width: 40%;
    }
  }
}

.faq-topics {
  margin-bottom: 2em;
  padding: 0;
  list-style: none;
  text-align: center;

  .faq-topic-item {
    display: inline-block;
    margin: 0 10px 10px 0;
    width: 100%;

    @include media($media-not-mobile) {
      width: 45%;
    }

    @include media($media-not-portable) {
      width: 30%;
      font-size: 1.2em;
    }
  }

  .faq-topic-link {
    display: inline-block;
    border: 1px solid #e0e0e0;
    padding: $site-margin-half $site-margin;
    width: 100%;
    text-decoration: none;
    line-height: 24px;
    color: $color-info-button-inactive;
    font-size: 1.1em;
    font-weight: 700;

    &.active {
      background: $color-partner-secondary;
      color: $color-info-button;
    }
  }

  @include media($media-not-portable) {
    margin-top: 0;
  }
}

.faq-topic {
  display: none;

  &.active {
    display: block;
  }

  h2 {
    display: block;
    text-align: center;
    font-size: 1.7em;
  }

  dt {
    margin-top: 1em;
    color: #555;
    font-size: 1.1em;
    font-weight: bold;

    &.active,
    &:hover {
      color: #000;
    }

    &.active .icon {
      display: inline-block;
      transform: rotate(90deg);
    }

    &.highlighted {
      display: block;
      color: red;
    }

    @include media($media-not-portable) {
      font-size: 1.4em;
    }
  }

  dd {
    display: none;
    margin-bottom: $site-margin * 3;
    margin-left: 5px;
    border-left: 5px solid #f0f0f0;
    padding-left: 15px;

    &.active {
      display: block;
    }

    @include media($media-not-portable) {
      font-size: 1.1em;
    }
  }
}

.faq-topic-legacy-active {
  display: block;

  dt {
    color: #000;
  }

  dd {
    display: block;
  }
}

// stylelint-enable selector-no-qualifying-type
