.footer {
  border-top: 1px solid $color-footer-border-top;

  @include media($media-desktop) {
    background: $color-grey-50;
  }
}

.footer-panel-background {
  background: $color-white;
}

.footer-panel {
  @include content-container;

  padding: $site-margin * 3 0;
  text-align: center;
  color: $color-white;
  font-size: 1em;

  a {
    color: $color-link-footer;
  }

  li {
    display: inline-block;
  }

  .footer-navigation-link,
  .footer-site-link {
    margin: 0 calc($site-margin / 2);
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .footer-site-list {
    margin-top: $site-margin * 2;

    @include media($media-mobile) {
      .footer-site-link {
        display: block;
        margin-bottom: $site-margin;
      }

      .footer-site-spacer {
        display: none;
      }
    }
  }

  .footer-navigation {
    a {
      text-decoration: none;
      font-weight: bold;
    }
  }
}

.footer-wrapper {
  border-top: 1px solid #eee;
  background: #fff;

  .footer {
    @include outer-container;

    background: #fff;
    padding: ($site-margin * 2) $site-margin;
  }
}

.footer-copyright {
  @include span-columns(12);

  text-align: center;

  @include media($media-not-mobile) {
    @include span-columns(6);

    float: left;
    text-align: left;
  }
}

.footer-nav-wrapper {
  @include span-columns(12);

  margin-bottom: $site-margin;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;

    li {
      padding-right: 0;
      padding-bottom: 15px;
    }
  }

  a {
    text-decoration: none;
    color: $color-partner-primary;

    &:hover {
      text-decoration: underline;
    }
  }

  @include media($media-not-mobile) {
    @include span-columns(6);

    float: right;
    margin-bottom: 0;

    ul {
      display: flex;
      justify-content: flex-end;
      text-align: right;

      li:first-child {
        padding-right: 15px;
        padding-bottom: 0;
      }
    }
  }
}
