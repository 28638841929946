$header-height: 62px;
$partner-logo-width: 250px !default;
$partner-logo-padding-y: 10px !default;
$color-header-background: $color-partner-primary !default;
$color-header-color: color-contrast($color-header-background) !default;
$header-tools-border: mix-with-white($color-partner-primary, 25%) !default;
$header-tools-background-hover: mix-with-white($color-partner-primary, 30%) !default;

$header-button-background: transparent !default;
$header-button-border: transparent !default;
$header-button-color: color-contrast($header-button-background) !default;
$header-button-hover-background: transparent !default;
$header-button-hover-border: transparent !default;
$header-button-hover-color: color-contrast($header-button-hover-background) !default;
$header-button-active-background: transparent !default;
$header-button-active-border: transparent !default;
$header-button-active-color: color-contrast($header-button-active-background) !default;
$header-button-disabled-background: $header-button-background !default;
$header-button-disabled-border: $header-button-border !default;
$header-button-disabled-color: color-contrast($header-button-disabled-background) !default;

.header-responsive {
  background-color: $color-header-background;
  width: 100%;
  color: $color-header-color;
}

.header {
  @include outer-container;

  height: $header-height;
}

.header-main-wrapper {
  @include span-columns(8);

  display: flex;
  height: 100%;
}

.header-menu-list {
  display: inline-block;
  margin: 0;
  line-height: $header-height;
}

.header-menu-item {
  display: inline;
  align-items: center;
  margin-left: $site-margin * 2;
  font-size: $font-size-mobile;

  a {
    text-decoration: none;
    color: $color-white;

    &:hover {
      color: $color-partner-secondary;
    }
  }
}

.partner-logo {
  padding: $partner-logo-padding-y $site-margin;
  max-width: $partner-logo-width;
  height: 100%;
  white-space: nowrap;
}

.partner-logo-img-desktop {
  max-width: 100%;
  max-height: 100%;
}

.partner-logo-img-mobile {
  display: none;
}

.header-tools-wrapper {
  @include span-columns(4);
  @include omega;
}

.header-tools {
  float: right;
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: $header-height;

  .language-picker-flag {
    display: inline-block;
  }
}

.header-tools-item {
  position: relative;
  float: left;
  border-left: 1px solid $header-tools-border;

  &:hover {
    background-color: $header-tools-background-hover;

    .header-tools-subitems,
    .currency-hidden-list {
      display: block;
    }
  }

  &:last-child {
    .header-tools-subitems {
      right: 1px;
    }
  }
}

.header-tools-link,
.header-tools-item.header-link-currency-picker .currency-picker-selected {
  display: inline-block;
  cursor: pointer;
  padding: 0 $site-margin;
  text-decoration: none;
  color: $color-header-color;

  .icon-chevron-down {
    display: none;
  }
}

.header-tools-subitems,
.header-link-currency-picker .currency-hidden-list {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 100;
  margin: 0 0 0 -1px;
  border-bottom: 2px solid $header-tools-background-hover;
  box-shadow: 0 0 4px #ccc;
  background: #fff;
  padding: 0;
  width: 140px;
  list-style: none;
}

.header-link-currency-picker .currency-hidden-list {
  width: 220px;
}

.header-link-currency-picker .currency-hidden-list-item,
.header-tools-subitem {
  border-bottom: 1px solid #e4e4e4;

  a {
    display: block;
    padding: 0 $site-margin + 1;
    text-decoration: none;
    line-height: 2.8em;
    color: #666;

    &:hover {
      background-color: #f4f4f4;
    }
  }

  .language-picker-flag {
    margin-right: calc($site-margin / 2);
  }
}

.sidebar-toggle {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 $site-margin;
  height: $header-height;
  line-height: $header-height;

  i {
    display: block;
    font-size: 28px;
  }
}

@include media($media-mobile) {
  .header-main-wrapper {
    @include span-columns(12);
  }

  .header-navigation-wrapper {
    display: none;
  }

  .header-menu-list {
    display: none;
  }

  .partner-logo {
    @include span-columns(12);

    display: block;
    float: none;
    margin: 0 auto;
    padding: 14px $site-margin;
    text-align: center;
  }

  .partner-logo-img-desktop {
    display: none;
  }

  .partner-logo-img-mobile {
    display: inline-block;
    height: 100%;
  }

  .header-tools-wrapper {
    display: none;
  }

  .sidebar-toggle {
    display: block;
  }
}

.sidebar-selector {
  .header-link {
    display: inline-block;
  }

  .header-link,
  .header-link-language-picker,
  .header-link-currency-picker {
    position: relative;
    padding: 0 $site-margin-half;
    text-decoration: none;
    color: inherit;

    i {
      padding: 4px;
      vertical-align: middle;
    }
  }

  .header-link-language-picker,
  .header-link-currency-picker {
    display: table;
    position: relative;
    margin: 0 auto;
    padding: 0 calc($site-margin / 4);
    text-decoration: none;
    color: inherit;

    i {
      padding: 4px;
      vertical-align: middle;
    }

    .language-picker-selected,
    .currency-picker-selected {
      display: table-row;
    }

    .language-hidden-select,
    .currency-hidden-select {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 999;
      width: 100%;
      min-width: 50px;
      color: $color-text-main;
    }
  }

  .header-link-language-picker {
    .language-picker-flag {
      display: inline-block;
      max-height: 32px;
      vertical-align: middle;
    }
  }
}

.btn-manage-booking {
  @include button-variant(
    $header-button-background,
    $header-button-border,
    $header-button-color,
    $header-button-hover-background,
    $header-button-hover-border,
    $header-button-hover-color,
    $header-button-active-background,
    $header-button-active-border,
    $header-button-active-color,
    $header-button-disabled-background,
    $header-button-disabled-border,
    $header-button-disabled-color
  );
}
