.fixed-bg {
  display: none;
}

.breadcrumbs-panel {
  padding: $site-margin;
}

.search-loading-container {
  .loading-text {
    @include media($media-not-mobile) {
      font-size: 2.5em;
    }
  }
}

// Content like Privacy policy
.text-content-header {
  background-image: $background-image;
  background-image: $background-overlay-gradient, $background-image;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 100%;

  .text-content-tagline {
    text-shadow: 0 1px 0 $text-shadow-color;
    color: $color-white;
    font-family: $font-brand-bold;
    font-size: 2.5em;
    font-weight: 700;
  }
}

.text-content {
  a {
    color: $color-partner-primary;
  }
}

.jumbotron {
  h1 {
    background: $color-partner-yellow;
  }
}

.faq-topics .faq-topic-item .faq-topic-link {
  &.active {
    background: $color-partner-yellow;
  }
}
